import { Col, Row, Spin } from 'antd';
import DataGrid, {
  Column,
  Export,
  Grouping,
  Pager,
  Sorting,
  HeaderFilter,
  ColumnFixing,
  FilterRow,
  Paging,
} from 'devextreme-react/data-grid';

import { ContractAPI } from '../../../Api/ContractAPI';
import { SignaturitAPI } from '../../../Api/SignaturitAPI';

import { useTableAllContracts } from '../../../Hooks/useTableAllContracts';

import { ITableAllContracts } from './ITableAllContracts';
import { languages } from '../../../Models/languages';
import { LoadingOutlined } from '@ant-design/icons';

const TableAllContracts = ({
	baseUrl,
	translations,
	userIdInstitution,
	filteredContracts,
	getAuthToken,
	handleChangeFilteredContracts,
	handleChangeContracts
}: ITableAllContracts) => {
	const contractAPI = ContractAPI(baseUrl, getAuthToken);
	const signaturitAPI = SignaturitAPI(baseUrl, getAuthToken);
	
	const {
		cellRenderEditIcon,
		loading,
		AmountFormat,
		cellRenderCancelIcon,
	} = useTableAllContracts({
		contractAPI,
		signaturitAPI,
		userIdInstitution,
		handleChangeFilteredContracts,
		handleChangeContracts,
		translations
	});


	return (
		<Row className="table-all-contracts" justify="center">
			{filteredContracts ?
				<Col span={24}>
						<Spin
							spinning={loading}
							indicator={
								<LoadingOutlined style={{ fontSize: 34, color: "black" }} spin />
							}
						>
						<DataGrid
							id="General"
							showBorders={true}
							height={'75vh'}
							remoteOperations={true}
							allowColumnResizing={true}
							columnResizingMode={"widget"}
							columnMinWidth={60}
							rowAlternationEnabled={true}
							showRowLines={true}
							hoverStateEnabled={true}
							dataSource={filteredContracts}
						>
							<HeaderFilter visible={true} />
							<Export enabled={true} />
							<Sorting mode="multiple" />
							<ColumnFixing enabled={true} />
							<FilterRow visible={true} />
							<Grouping contextMenuEnabled={true} expandMode="rowClick" />
							<Grouping autoExpandAll={false} />
							<Paging defaultPageSize={50} />
							<Pager
								showPageSizeSelector={true}
								allowedPageSizes={[20, 50, 100]}
								showNavigationButtons={true}
								visible={true}
								showInfo={true}
							/>
							<Column dataField="id" caption="Contract ID" width={60} />
							<Column dataField="investorName" caption={translations[languages.nombre]} minWidth={220} alignment='center'/>
							<Column dataField="personType" caption={translations[languages.type]} alignment='center' width={100}/>
							<Column dataField="vehicle" caption={translations[languages.vehiculo]} alignment='center' minWidth={220}/>
							<Column dataField="commitment" caption={translations[languages.compromiso]} width={120} alignment='right' format={AmountFormat} />
							<Column dataField="state" caption={translations[languages.estado]} alignment='center' width={120}/>
							<Column dataField="created_At" caption={translations[languages.fecha]} width={110} dataType="date" format="dd/MM/yyyy" alignment='center'/>
							<Column dataField="bankName" caption="Colocador" visible={userIdInstitution === 536} minWidth={150} alignment='center'/>
							{/* <Column caption="Docs" cellRender={cellRendeDocs} width={50} alignment='center' allowEditing={false} /> */}
							<Column caption="Edit" cellRender={cellRenderEditIcon} width={60} alignment='center' />
							{/* <Column caption="Delete" cellRender={cellRenderEditIcon} width={60} alignment='center' /> */}
							<Column caption="Cancel/Delete" cellRender={cellRenderCancelIcon} width={60} alignment='center' allowEditing={false} />
							{/* <Column caption="Reactivar" visible={userIdInstitution === 536} cellRender={cellRenderRollbackIcon} width={60} alignment='center' allowEditing={false} /> */}
						</DataGrid>
					</Spin>
				</Col> 
				: null
			}
		</Row>
	);
};

export default TableAllContracts;
