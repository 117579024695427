import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

import { TaxHolder } from '../Models/TaxHolders';

export const TaxHoldersAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getTaxHolders = (ContractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/TaxHolder/GetTaxHolder`;
        return api.get(serviceUrl, { params: { ContractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const saveTaxHolders = (data: TaxHolder[]) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/TaxHolder/SaveTaxHolder`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
    const updateTaxHolders = (data: TaxHolder[]) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/TaxHolder/UpdateTaxHolder`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

	const deleteTaxHolders = (taxHolder: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/TaxHolder/DeleteTaxHolder`;
        return api.delete(serviceUrl, {params: { id: taxHolder }})
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
		getTaxHolders,
		saveTaxHolders,
		updateTaxHolders,
		deleteTaxHolders
	};
};
