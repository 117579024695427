import { useState } from "react";

const useHomePage = () => {
    const [filteredContracts, setFilteredContracts] = useState<any[]>([]);
    const [contractView, setContractView] = useState<'all' | 'inRevision'>('all');
    const [contracts, setContracts] = useState<any[]>([]);
  
    const handleChangeFilteredContracts = (filteredContracts: any[]) =>
      setFilteredContracts(filteredContracts);
    const handleChangeContracts = (contracts: any[]) => setContracts(contracts);
  
  return {
    filteredContracts,
    setFilteredContracts,
    contractView,
    setContractView,
    contracts,
    handleChangeFilteredContracts,
    handleChangeContracts
  }
}

export default useHomePage