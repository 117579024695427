import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

import { Contract, ContractData, NewContractData } from '../Models/Contracts';

export const ContractAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const postContract = (data: NewContractData) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateContract = (data: Contract) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/UpdateContract`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const getContract = (contractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/GetContract`;
        return api.get(serviceUrl, { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const deleteContract = (contractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/DeleteContract`;
        return api.delete(serviceUrl, { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
    const getAllContracts = (idInstitution: string) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/GetAllContracts`;
        return api.get(serviceUrl, { params: { idInstitution } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const getInRevisionContracts = () => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/GetInRevisionContracts`;
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const rollbackContract = (contractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/Rollback?contractId=${contractId}`;
        return api.post(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const checkAllowedAmount = (VehicleId: number, idInstitution: number, commitment: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Contract/IsAllowedAmount`;
        return api.get(serviceUrl, { params: { VehicleId, idInstitution, commitment } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const getShareTypes = () => {
        const serviceUrl = `${baseUrl}/api/v1/SharesType`;
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        postContract,
        updateContract,
        getContract,
        deleteContract,
        getAllContracts,
        getInRevisionContracts,
        rollbackContract,
        checkAllowedAmount,
        getShareTypes
    };
};