import "./HomePage.scss";
import Sidebar from "./Sidebar/Sidebar";
import TableAllContracts from "./TableAllContracts/TableAllContracts";
import { CaretRightOutlined } from "@ant-design/icons";
import useHomePage from "../../Hooks/useHomePage";
import TableInRevision from "./TableInRevision/TableInRevision";
import { IHomePage } from "./IHomePage";


const HomePage = ({
  baseUrl,
  translations,
  userEmail,
  userId,
  userIdInstitution,
  institutions,
  getAuthToken,
}: IHomePage) => {

	const {
		filteredContracts, 
		contractView, 
		contracts, 
		handleChangeFilteredContracts, 
		handleChangeContracts,
		setContractView
	}  = useHomePage();

  return (
    <div className="homepage">
      <Sidebar 
		userId={userId}
		userIdInstitution={userIdInstitution}
		translations={translations}
		institutions={institutions}
		contracts={contracts}
		handleChangeFilteredContracts={handleChangeFilteredContracts}
		userEmail={userEmail}
	  />
	  <div className="homepage-tables">
		<div className="homepage-header">
			<h2><span><CaretRightOutlined /></span>{translations.welcome}</h2>
			<div className="homepage-table-selector">
				<button onClick={() => setContractView('all')} className={`${contractView === 'all' ? 'selected-btn' : ''}`}>{translations.allContracts}</button>
				{userEmail === 'qfunds' && <button onClick={() => setContractView('inRevision')} className={`${contractView === 'inRevision' ? 'selected-btn' : ''} inrevision-btn`}>{translations.inRevision}</button>}
			</div>
		</div>
		{contractView === 'all' && (
			<TableAllContracts
				baseUrl={baseUrl}
				translations={translations}
				userIdInstitution={userIdInstitution}
				filteredContracts={filteredContracts}
				getAuthToken={getAuthToken}
				handleChangeFilteredContracts={handleChangeFilteredContracts}
				handleChangeContracts={handleChangeContracts}
				/>
		)}
		{(userEmail === 'qfunds' && contractView === 'inRevision') && 
			<TableInRevision
				baseUrl={baseUrl}
				translations={translations}
				getAuthToken={getAuthToken}
			/>
		} 
		</div>
    </div>
  );
};

export default HomePage;
