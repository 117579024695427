import { useEffect, useState } from "react";
import { InstitutionValues } from "../Models/Institution";
import { IUseGraphic } from "./IUseGraphic";

export const useGraphic = ({
    userIdInstitution,
    institutions,
    contracts,
    handleChangeFilteredContracts
}: IUseGraphic) => {
    
    const [tabItems, setTabItems] = useState<InstitutionValues[]>([]);
    const [selectedInstitution, setSelectedInstitution] = useState<number>(0) 
    const [pieData, setPieData] = useState();
    const [selectedVehicle, setSelectedVehicle] = useState<number>(0) 
    const [percent, setPercent] = useState({
        confirmed: "0",
        pre: "0",
        disp: "0"
    });

    useEffect(()=>{
        if(institutions) {
            institutions?.length > 0 && setSelectedInstitution(userIdInstitution)
        }
    },[institutions])

    const onChangeInstitution = (e: number) => {
        handleChangeFilteredContracts(contracts?.filter((x:any)=> x.bankId === e))
        setSelectedInstitution(e)
        getContractValues(e, 0)
    };
    
    const onChangeVehicle = (idInstitution: number, vehicleIndex: number) => {
        setSelectedVehicle(vehicleIndex)
        getContractValues(idInstitution, vehicleIndex);
        if(tabItems[vehicleIndex].vehicleId === -1){
            handleChangeFilteredContracts(contracts?.filter((x:any)=> x.bankId === selectedInstitution))
        }else{
            handleChangeFilteredContracts(contracts?.filter((x:any)=> x.vehicleId === tabItems[vehicleIndex].vehicleId && x.bankId === selectedInstitution));
        }
    }

    const getContractValues = (idInstitution: number | string, vehicleIndex: number) => {
        if (institutions.length > 0) {
            setPercent({
                confirmed: "0",
                pre: "0",
                disp: "0",
            });
            setTabItems([])
            let institutionData = institutions.filter(x => x.idInstitution.toString() === idInstitution.toString())[0];
            if (institutionData.institutionValues.length > 0) {
                let newData = [...institutionData.institutionValues]
                let sumObj: any = {};
                institutionData.institutionValues.forEach((obj: any) => {
                    Object.keys(obj).forEach(key => {
                        if(key === 'vehicleId'){
                            sumObj[key] = -1;
                        }
                        else if (typeof obj[key] === 'string') {
                            sumObj[key] = 'All';
                        } else {
                            if (!sumObj[key]) {
                                sumObj[key] = 0;
                            }
                            sumObj[key] += obj[key];
                        }
                    })
                })
                
                newData.length === institutionData.institutionValues.length && newData.unshift(sumObj);
                setTabItems(newData);
                let res = newData[vehicleIndex];
                let pieData: any = [
                    {type: "Total Firmado",
                    value: res.confirmed !== null ? res.confirmed : 0},
                    {type: "Total en Proceso",
                    value: res.reserved !== null ? res.reserved : 0},
                    {type: "Total Disponible",
                    value: res.available !== null ? res.available : 0
                }]
                setPercent({
                    confirmed: (((res.confirmed !== null ? res.confirmed : 0) / res.maxAvailable) * 100).toFixed(2),
                    pre: (((res.reserved !== null ? res.reserved : 0) / res.maxAvailable) * 100).toFixed(2),
                    disp: (((res.available !== null ? res.available : 0) / res.maxAvailable) * 100).toFixed(2)
                })
                setPieData(pieData);
            }
        }
    };

    return {
        getContractValues,
        pieData,
        percent,
        tabItems,
        onChangeInstitution,
        selectedInstitution,
        setSelectedInstitution,
        onChangeVehicle,
        selectedVehicle
    }
}