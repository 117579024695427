import { Button, Col, Divider, Input, Radio, Result, Row, Spin, Tooltip, Select, Checkbox } from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { amountOptionsLanguages, incomeOptionsLanguages, sectorOptionsLanguages, sourceOptionsLanguages } from '../PhysicalInvestorForm/ActivityLists';

import { InvestorAPI } from '../../../Api/InvestorAPI';
import { useLegalInvestorForm } from '../../../Hooks/useLegalInvestorForm';

import { ILegalInvestorForm } from './ILegalInvestorForm';

import './LegalInvestorForm.scss';
import FormLayout from '../../FormLayout/FormLayout';
import { ContractAPI } from '../../../Api/ContractAPI';

const { Option } = Select;

const LegalInvestorForm = ({
	baseUrl,
	translations,
	getAuthToken,
	unlockNextStep
}: ILegalInvestorForm) => {
	
	const contractAPI = ContractAPI(baseUrl, getAuthToken);
	const investorAPI = InvestorAPI(baseUrl, getAuthToken);
	const {
		loading,
		noContent,
		errors,
		investorData,
		nationalitiesList,
		activityInList,
		sourceInList,
		handleChangeData,
		handleSelectData,
		saveData,
		handleCheckedData,
		validateData,
		contractLanguage
	} = useLegalInvestorForm({
		translations,
		investorAPI,
		contractAPI,
		unlockNextStep
	});

	console.log(sectorOptionsLanguages);

	return (
		<FormLayout 
			translations={translations}
			loading={loading}
			title={translations.formularioInversor}
			subTitle={translations.encabezadoFormulario}
			onClick={saveData}
			disabledSaveButton={noContent}
			nextStep={unlockNextStep}
			enableNextStep={validateData(false)}
		>	
			{!noContent
				? <>
					<Row gutter={[16, 16]} >
						<Col xs={24} md={12} lg={12} className="titles" >
							<label className="required"> {translations.nombreDelFirmante}</label>
							<Input
								type="text"
								name="name"
								value={`${investorData.name} ${investorData.lastName}`}
								onChange={handleChangeData}
								status={errors.name ? 'error' : ''}
								disabled
							/>
						</Col>

						<Col xs={24} md={12} lg={12} className="titles">
							<p>
								<label className="required"> {translations.nombreEntidad} </label>
								<Tooltip placement="topLeft" title={translations.tooltipformaLegal} >
									<InfoCircleOutlined className="tooltip" />
								</Tooltip>
							</p>
							<Input
								type="text"
								name="legalForm"
								value={investorData.legalForm}
								onChange={handleChangeData}
								status={errors.legalForm ? 'error' : ''}
							/>
							<span className={`error ${errors.legalForm ? 'show' : null}`}>{translations.completeFormaLegal}</span>
						</Col>

						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.entityType}</label>
							<Select
								className="input-field"
								value={investorData.entityType}
								onChange={handleSelectData('entityType')}
								status={errors.entityType ? 'error' : ''}
								placeholder={translations.seleccioneOpcion}
								showSearch
								filterOption={(input, option) =>
									(option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
								}
							>
								<Option value="corporation">{translations.incorporatedCompany}</Option>
								<Option value="limited">{translations.limitedLiabilityCompany}</Option>
								<Option value="sole">{translations.soleProprietorshipCompany}</Option>
								<Option value="nonprofit">{translations.nonProfitCompany}</Option>
								<Option value="ngo">{translations.nonGovernmentalOrganizations}</Option>
								<Option value="associations">{translations.associations}</Option>
								<Option value="religious">{translations.religiousOrder}</Option>
								<Option value="ventureCapital">{translations.ventureCapital}</Option>
							</Select>
							<span className={`error ${errors.nationality ? 'show' : null}`}>{translations.completeEntityType}</span>
						</Col>

						<Col xs={24} md={12} lg={12} className="titles">
							<label>{translations.nombreGestor}</label>
							<Input
								type="text"
								name="managerName"
								value={investorData.managerName}
								onChange={handleChangeData}
							/>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.fechaLugarConstitucion}</label>
							<Input
								type="text"
								name="constitutionDate"
								value={investorData.constitutionDate}
								onChange={handleChangeData}
								status={errors.constitutionDate ? 'error' : ''}
							/>
							<span className={`error ${errors.constitutionDate ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.jurisdiccion}</label>
							<Input
								type="text"
								name="jurisdiction"
								value={investorData.jurisdiction}
								onChange={handleChangeData}
								status={errors.jurisdiction ? 'error' : ''}
							/>
							<span className={`error ${errors.jurisdiction ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> Código LEI</label>
							<Input
								type="text"
								name="lei"
								value={investorData.lei}
								onChange={handleChangeData}
								status={errors.lei ? 'error' : ''}
							/>
							<span className={`error ${errors.lei ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.domicilioSocial}</label>
							<Input
								type="text"
								name="registeredOffice"
								value={investorData.registeredOffice}
								onChange={handleChangeData}
								status={errors.registeredOffice ? 'error' : ''}
							/>
							<span className={`error ${errors.registeredOffice ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label>{translations.direccionpostal}</label>
							<Input
								type="text"
								name="postalAddress"
								value={investorData.postalAddress}
								onChange={handleChangeData}
							/>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label>{translations.paginaWeb}</label>
							<Input
								type="text"
								name="webPage"
								value={investorData.webPage}
								onChange={handleChangeData}
							/>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.personaContacto}</label>
							<Input
								type="text"
								name="contactPerson"
								value={investorData.contactPerson}
								onChange={handleChangeData}
								status={errors.contactPerson ? 'error' : ''}
							/>
							<span className={`error ${errors.contactPerson ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.emailContacto}</label>
							<Input
								type="text"
								name="emailContact"
								value={investorData.emailContact}
								onChange={handleChangeData}
								status={errors.emailContact ? 'error' : ''}
							/>
							<span className={`error ${errors.emailContact ? 'show' : null}`}>{translations.completeEmailValido}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.prefix}</label>
							<Input
								type="text"
								name="phonePrefix"
								value={investorData.phonePrefix}
								onChange={handleChangeData}
								status={errors.phonePrefix ? 'error' : ''}
							/>
							<span className={`error ${errors.phonePrefix ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.telefonoContacto}</label>
							<Input
								type="text"
								name="phoneNumber"
								value={investorData.phoneNumber}
								onChange={handleChangeData}
								status={errors.phoneNumber ? 'error' : ''}
							/>
							<span className={`error ${errors.phoneNumber ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.registroMercantil}</label>
							<Input
								type="text"
								name="commercialRegister"
								value={investorData.commercialRegister}
								onChange={handleChangeData}
								status={errors.commercialRegister ? 'error' : ''}
							/>
							<span className={`error ${errors.commercialRegister ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.nacionalidad}</label>
							<Select
								className="input-field"
								value={investorData.nationality}
								onChange={handleSelectData('nationality')}
								status={errors.nationality ? 'error' : ''}
								placeholder={translations.seleccioneOpcion}
								showSearch
								filterOption={(input, option) =>
									(option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
								}
							>
								{nationalitiesList.map((nationality: any)=> {
									return <Option value={nationality.name_lang2}>{nationality.name_lang2}</Option>
								})}
							</Select>
							<span className={`error ${errors.nationality ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> CIF </label>
							<Input
								type="text"
								name="cif"
								value={investorData.cif}
								onChange={handleChangeData}
								status={errors.cif ? 'error' : ''}
							/>
							<span className={`error ${errors.cif ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<p>
								<label className="required"> {translations.paisResidenciaFiscal} </label>
								<Tooltip placement="topLeft" title={translations.tooltipPaisResidenciaFiscal} >
									<InfoCircleOutlined className="tooltip" />
								</Tooltip>
							</p>
							<Input
								type="text"
								name="countryTaxResidence" 
								value={investorData.countryTaxResidence}
								onChange={handleChangeData}
								status={errors.countryTaxResidence ? 'error' : ''}
							/>
							<span className={`error ${errors.countryTaxResidence ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<p>
								<label>{translations.identificacionFiscal} </label>
								<Tooltip placement="topLeft" title={translations.tooltipIdentificacionFiscal} >
									<InfoCircleOutlined className="tooltip" />
								</Tooltip>
							</p>
							<Input
								type="text"
								name="taxIdentification"
								value={investorData.taxIdentification}
								onChange={handleChangeData}
							/>
						</Col>
						<Col xs={24} md={24} lg={24} className="titles">
							<label>{translations.encabezadoRazon}</label>
							<Row className="nif-radio-group">
								<Radio.Group
									name="notNIFReason"
									value={investorData.notNIFReason}
									onChange={handleChangeData}
								>
									<Radio style={{ whiteSpace: 'normal' }} value={1}>{translations.razonA}</Radio> <br></br>
									<Radio style={{ whiteSpace: 'normal' }} value={2}>{translations.razonB}</Radio>
									{/*  Este input, sólo se habilita si se selecciona la Razón B */}
									<Input
										placeholder={translations.placeHolderRazon}
										disabled={investorData.notNIFReason !== 2}
										name="notNIFDetailReason"
										value={investorData.notNIFDetailReason}
										onChange={handleChangeData}
									/>
									<Radio style={{ whiteSpace: 'normal' }} value={3}>{translations.razonC}</Radio>
								</Radio.Group>
							</Row>
						</Col>
					</Row>
					<Divider />
					<Row gutter={[16, 16]} align="bottom">
						{!activityInList ? 
							<>
								<Col xs={24} md={6} lg={6} className="titles">
									<label className="required"> {translations.sectorFirmante} </label>
									<Select
										className="input-field"
										value={investorData.sector}
										onChange={handleSelectData('sector')}
										status={errors.sector ? 'error' : ''}
										placeholder={translations.seleccioneOpcion}
										showSearch
										filterOption={(input, option) =>
											(option!.children as unknown as string).includes(input)
										}
									>
										{sectorOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
										<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
									</Select>
									<span className={`error ${errors.sector ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
								</Col>
								<Col xs={24} md={6} lg={6} className="titles">
									<label className="required">{translations.otherActivity}</label>
									<Input 
										name="otherSector"
										value={investorData.otherSector}
										onChange={handleChangeData}
										/>
								</Col>							
							</>
							:
							<>
								<Col xs={24} md={12} lg={12} className="titles">
									<label className="required"> {translations.sectorFirmante} </label>
									<Select
										className="input-field"
										value={investorData.sector}
										onChange={handleSelectData('sector')}
										status={errors.sector ? 'error' : ''}
										placeholder={translations.seleccioneOpcion}
									>
										{sectorOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
										<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
									</Select>
									<span className={`error ${errors.sector ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
								</Col>
							</>
						}
						{!sourceInList ? 
							<>
								<Col xs={24} md={6} lg={6} className="titles">
									<label className="required"> {translations.fundSources}</label>
									<Select
										className="input-field"
										value={investorData.source}
										onChange={handleSelectData('source')}
										status={errors.source ? 'error' : ''}
										placeholder={translations.seleccioneOpcion}
									>
										{sourceOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
										<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
									</Select>
									<span className={`error ${errors.source ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
								</Col>
								<Col xs={24} md={6} lg={6} className="titles">
									<label className="required">{translations.otherSource}</label>
									<Input 
										name="otherSource"
										value={investorData.otherSource}
										onChange={handleChangeData}
										/>
								</Col>							
							</>
							:
							<>
								<Col xs={24} md={12} lg={12} className="titles">
									<label className="required"> {translations.fundSources} </label>
									<Select
										className="input-field"
										value={investorData.source}
										onChange={handleSelectData('source')}
										status={errors.source ? 'error' : ''}
										placeholder={translations.seleccioneOpcion}
									>
										{sourceOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
										<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
									</Select>
									<span className={`error ${errors.source ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
								</Col>
							</>
						}
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.anualIncome}</label>
							<Select
								className="input-field"
								value={investorData.incomes}
								onChange={handleSelectData('incomes')}
								status={errors.incomes ? 'error' : ''}
								placeholder={translations.seleccioneOpcion}
							>
								{incomeOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
							</Select>
							<span className={`error ${errors.incomes ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.netWorth}</label>
							<Select
								className="input-field"
								value={investorData.lastPatrimony}
								onChange={handleSelectData('lastPatrimony')}
								status={errors.lastPatrimony ? 'error' : ''}
								placeholder={translations.seleccioneOpcion}
							>
								{amountOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
							</Select>
							<span className={`error ${errors.lastPatrimony ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.territoriosOpera}</label>
							<Input
								type="text"
								name="operationCountries"
								value={investorData.operationCountries}
								onChange={handleChangeData}
								status={errors.operationCountries ? 'error' : ''}
							/>
							<span className={`error ${errors.operationCountries ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
					</Row>
					<Divider />
					<Row gutter={[16, 16]}>
						<Col xs={24} md={12} lg={12} className="titles">
							<p>
								<label className="required"> {translations.bancoDepositos} </label>
								<Tooltip placement="topLeft" title={translations.tooltipbanco} >
									<InfoCircleOutlined className="tooltip" />
								</Tooltip>
							</p>
							<Input
								type="text"
								name="bankDeposits"
								value={investorData.bankDeposits}
								onChange={handleChangeData}
								status={errors.bankDeposits ? 'error' : ''}
							/>
							<span className={`error ${errors.bankDeposits ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.direccionBanco}</label>
							<Input
								type="text"
								name="bankAddress"
								value={investorData.bankAddress}
								onChange={handleChangeData}
								status={errors.bankAddress ? 'error' : ''}
							/>
							<span className={`error ${errors.bankAddress ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.ibanFisica}</label>
							<Input
								type="text"
								name="iban"
								value={investorData.iban}
								onChange={handleChangeData}
								status={errors.iban ? 'error' : ''}
							/>
							<span className={`error ${errors.iban ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.codigoBic}</label>
							<Input
								type="text"
								name="biC_SWIFT"
								value={investorData.biC_SWIFT}
								onChange={handleChangeData}
								status={errors.biC_SWIFT ? 'error' : ''}
							/>
							<span className={`error ${errors.biC_SWIFT ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Checkbox
							name="organism"
							checked={investorData.organism}
							value= {investorData.organism}
							onChange={handleCheckedData}
						>
							<label>{translations.encabezadoOcde}</label>
						</Checkbox>
					</Row>

					{/* Los 4 campos siguientes sólo se muestrar si se selecciona SI */}
					{investorData.organism
						? <Row gutter={[16, 16]}>
							<Col xs={24} md={24} lg={24} className="titles">
								{translations.encabezadoSiOcde}
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.nombreAutoridad}</label>
								<Input
									type="text"
									name="regulationOrganism"
									value={investorData.regulationOrganism}
									onChange={handleChangeData}
									status={errors.regulationOrganism ? 'error' : ''}
								/>
								<span className={`error ${errors.regulationOrganism ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.paisAutoridad}</label>
								<Input
									type="text"
									name="countryRegulator"
									value={investorData.countryRegulator}
									onChange={handleChangeData}
									status={errors.countryRegulator ? 'error' : ''}
								/>
								<span className={`error ${errors.countryRegulator ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.paginaWebSi}</label>
								<Input
									type="text"
									name="webRegulator"
									value={investorData.webRegulator}
									onChange={handleChangeData}
									status={errors.webRegulator ? 'error' : ''}
								/>
								<span className={`error ${errors.webRegulator ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.numeroRegistroSi}</label>
								<Input
									type="text"
									name="regulatoryRecord"
									value={investorData.regulatoryRecord}
									onChange={handleChangeData}
									status={errors.regulatoryRecord ? 'error' : ''}
								/>
								<span className={`error ${errors.regulatoryRecord ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
						</Row>
						: null
					}
					<Divider />
					<Row>
						<Checkbox
							name="contributor"
							checked={investorData.contributor}
							value= {investorData.contributor}
							onChange={handleCheckedData}
						>
							<label>{translations.encabezadoCotiza}</label>
						</Checkbox>
					</Row>

					{/* Los 4 campos siguientes sólo se muestrar si se selecciona SI */}
					{investorData.contributor
						? <Row gutter={[16, 16]}>
							<Col xs={24} md={24} lg={24} className="titles">
								{translations.encabezadoSiOcde}
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.nombreAutoridad}</label>
								<Input
									type="text"
									name="marketName"
									value={investorData.marketName}
									onChange={handleChangeData}
									status={errors.marketName ? 'error' : ''}
								/>
								<span className={`error ${errors.marketName ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.paisAutoridad}</label>
								<Input
									type="text"
									name="marketCountry"
									value={investorData.marketCountry}
									onChange={handleChangeData}
									status={errors.marketCountry ? 'error' : ''}
								/>
								<span className={`error ${errors.marketCountry ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.paginaWebSi}</label>
								<Input
									type="text"
									name="marketWeb"
									value={investorData.marketWeb}
									onChange={handleChangeData}
									status={errors.marketWeb ? 'error' : ''}
								/>
								<span className={`error ${errors.marketWeb ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.numeroRegistroSi}</label>
								<Input
									type="text"
									name="marketRegistry"
									value={investorData.marketRegistry}
									onChange={handleChangeData}
									status={errors.marketRegistry ? 'error' : ''}
								/>
								<span className={`error ${errors.marketRegistry ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
						</Row>
						: null
					}
				</>
				:
				<Result
					status="warning"
					title={translations.messageContrato}
					extra={
						<Button type="primary" key="console">
							{translations.inicio}
						</Button>
					}
				/>
			}
		</FormLayout>
	);
};

export default LegalInvestorForm;
