import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message, notification, Popconfirm, Tooltip } from "antd";
import { PaperClipOutlined,DeleteOutlined,RollbackOutlined,CloseCircleOutlined, EditOutlined } from '@ant-design/icons';

import { IUseTableAllContracts } from "./IUseTableAllContracts";

const AmountFormat = { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 };
export const useTableAllContracts = ({
	contractAPI,
	signaturitAPI,
	userIdInstitution,
	handleChangeFilteredContracts,
	handleChangeContracts,
	translations
}: IUseTableAllContracts) => {
	const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<any>(0);

    useEffect(() => {
        validateToken();
    }, [userIdInstitution]);

    const validateToken = () => {
      	if (userIdInstitution) {
			setLoading(true);
			contractAPI.getAllContracts(userIdInstitution.toString()).then(res => {
				if(res.success){
					handleChangeContracts(res.data);
					handleChangeFilteredContracts(res.data)
				}
				setLoading(false);
			})
	}}

    const cancelContract = (id: any, provider: string) => (
		signaturitAPI.cancelContract(id, provider).then((res: any) => {
			if(res.success){
				notification.success({ message: 'Contract was canceled' });
				validateToken();
			}else{
				notification.error({ message: translations.contractNotDeleted });
			}
			
		})
	);
    
	const deleteContract = (contractId: number) => (
        contractAPI.deleteContract(contractId).then((res: any) => {
			if(res.success){
				notification.success({ message: 'Contract was deleted' });
				validateToken();
			}else{
				notification.error({ message: translations.contractNotDeleted });
			}
        })
	);
    
    const cellRenderEditIcon = (row: any) => {
      	setState(row.data.idContractState);
        return (
			<div>
				{<Tooltip placement="top" title={'Editar'}>
					<button className="btnDelete"  disabled={row.data.idContractState !== 1} onClick={() => editarContrato(row.data.id)} > 
						<EditOutlined type="edit" /> 
					</button>
				</Tooltip> }
        	</div>
		);
    };

    const cellRendeDocs = (row: any) => <div>{row.data.totalDocuments}&nbsp;<PaperClipOutlined /></div>;

    const cellRenderCancelIcon = (row: any) => {
		if(row.data.idContractState === 3){
			return(			
			<Tooltip placement="bottom" title={'Cancel Contract'}>
				<Popconfirm
					title="Are you sure you want to cancel this Contract?"
					onConfirm={() => cancelContract(row.data.id, row.data.signatureProvider)}
					okText="Yes"
					cancelText="No"
					placement="left"
				>
					<button className="btnDelete"> 
						<CloseCircleOutlined />
					</button>
				</Popconfirm>
			</Tooltip>
			)
		}else if(row.data.idContractState === 5){
			return (
			<Tooltip placement="left" title={'Reactivate'}>
				<Popconfirm
					placement="topLeft" 
					title="Are you sure you want to change this contract state?"
					onConfirm={() => rollbackContract(row.data.id)}
					okText="Yes"
					cancelText="No"
				>
					<RollbackOutlined />
				</Popconfirm>
			</Tooltip>
			)
		}else if(row.data.idContractState === 1 || row.data.idContractState === 2){
			return (
			<Tooltip placement="bottom" title={'Delete Contract'}>
				<Popconfirm
					title="Are you sure you want to delete this Contract?"
					onConfirm={() => deleteContract(row.data.id)}
					okText="Yes"
					cancelText="No"
					placement="left"
				>
					<button className="btnDelete"> 
						<DeleteOutlined />
					</button>
				</Popconfirm>
			</Tooltip>
			)
		}
    };

/*     const cellRenderRollbackIcon = (row:any) => (
      	row.data.state === "Cancelado" ? 
			<Tooltip placement="left"  title={'Change State'}>
				<Popconfirm
					placement="topLeft" 
					title="Are you sure you want to change this contract state?"
					onConfirm={() => rollbackContract(row.data.id)}
					okText="Yes"
					cancelText="No"
				>
					<RollbackOutlined />
				</Popconfirm>
			</Tooltip>
			: null
	); */

	const rollbackContract = (contractId: number) => (
		contractAPI.rollbackContract(contractId).then(() => {
			notification.success({ message: 'Contract state has been updated' });
			validateToken();
		})
	);

    const editarContrato = (contractId: number) => {
        sessionStorage.isEditing = true;
        sessionStorage.isCreating = false;
        navigate(`/FormContract?ContractId=${contractId}`);
	};

    return {
        cellRenderEditIcon,
        loading,
        AmountFormat,
        cellRendeDocs,
        cellRenderCancelIcon,
        state,
    };
};