import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const DistributorsAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const saveDistributors = (data: any) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Distributors/SaveDistributors`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateDistributors = (data: any) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Distributors/UpdateDistributors`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

	const getDistributors = (contractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Distributors/GetDistributors`;
        return api.get(serviceUrl, { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        saveDistributors,
        updateDistributors,
        getDistributors,
    };
};