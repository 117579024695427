import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const BankAPI = (baseUrl: string |undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const get = () => {
        const serviceUrl = `${baseUrl}/Bank/GetAllBanks`;
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const getInstitutions = () => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Institution/GetInstitutionValues`;
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        get,
        getInstitutions
    };
};