import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, message } from "antd";


import { emptyCrs } from "../Assets/Data/EmptyValues";
import { CrsDTO } from "../Models/CrsAPI";
import { IUseCRS } from "./IUseCRS";

export const useCrs = ({
	translations,
	crsAPI,
	unlockNextStep
}: IUseCRS) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [crsData, setCrsData] = useState<CrsDTO>(emptyCrs);

	const radioStyle = {
		display: 'block',
		marginBottom:15,
		height: '30px',
		lineHeight: '30px',
	};
	
	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
		}
	}, [translations]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await crsAPI.getCRS(ContractId).then((res: any) => {
			if (res.success) {
				if(res.data){
					setCrsData({...res.data, idContract: ContractId});
				}else{
					setCrsData({...emptyCrs, idContract: ContractId});
				}
			}
			setLoading(false);
		})
		.catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
	};

	const getInfo = (e: any) => {
		const key = e.target.dataset.key;
		Modal.info({
			content: (
				<div>
					{translations[`tooltipCrs${key ? key : ''}`]}
				</div>
			),
			onOk() { },
		});
	};

	const postCrs = async() => {
		await crsAPI.saveCRS(crsData).then((res: any) => {
			if (res.success) {
				message.success(translations.datosGuardados);
				unlockNextStep();
				setLoading(false);
			}else{
				message.error(translations.errorGuardado);
				setLoading(false);
			}
		}
		).catch((err: any) => {
			console.log(err);
			message.error(translations.errorGuardado);
			setLoading(false)
		})
	}

	const updateCrs = async() => {
		await crsAPI.updateCRS(crsData).then((res: any) => {
			if (res.success) {
				message.success(translations.datosGuardados);
				setLoading(false);
			}else{
				message.error(translations.errorGuardado);
				setLoading(false);
			}
		}
		).catch((err: any) => {
			console.log(err);
			message.error(translations.errorGuardado);
			setLoading(false)
		}
		)
	}

	const saveData = async() => {
		setLoading(true);
		if (crsData.id === 0) {
			await postCrs();
		}
		else if (crsData.id > 0) {
			await updateCrs();
		}
		setLoading(false);
	};

	const handleChangeData = (e: any) => {
		const { name, value } = e.target;
		if(value !== 9){
			setCrsData((prevState: CrsDTO) => ({
				...prevState,
				otherText: '',
				[name]: value
			}));
		}
		else{
			setCrsData((prevState: CrsDTO) => ({
				...prevState,
				[name]: value
			}));
		}
	};

	return {
		loading,
		crsData,
		radioStyle,
		getInfo,
		handleChangeData,
		saveData,
	};
};