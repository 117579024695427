import { useNavigate } from "react-router-dom";
import { Button, Result, Spin } from "antd";
import { LoadingOutlined, ProfileOutlined } from "@ant-design/icons";

import { ContractAPI } from "../../Api/ContractAPI";
import { SignaturitAPI } from "../../Api/SignaturitAPI";
import { useCreateSignature } from "../../Hooks/useCreateSignature";

import { ICreateSignature } from "./ICreateSignature";

export interface recipients {
  email: string;
  name: string;
  type: string;
  require_file_attachment: number;
}

const CreateSignature = ({
  baseUrl,
  translations,
  getAuthToken,
}: ICreateSignature) => {
  const navigate = useNavigate();

  const {
    loading,
    sendedContract,
    sendContractToValidate,
    irAlInicio,
  } = useCreateSignature({
    translations,
    contractAPI: ContractAPI(baseUrl, getAuthToken),
    navigate,
  });

  return (
    <div className="data-wrapper">
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined style={{ fontSize: 34, color: "black" }} spin />
        }
      >
        {!sendedContract ? (
          <Result
            icon={<ProfileOutlined />}
            title={translations.contratoListoTitulo}
            subTitle={translations.contratoListo}
            extra={
              <Button
                type="primary"
                onClick={sendContractToValidate}
                loading={loading}
              >
                {translations.sendContractToApproval}
              </Button>
            }
          />
        ) : (
          <Result
            status="success"
            title={translations.contratoEnviado}
            extra={[
              <Button type="primary" key="console" onClick={irAlInicio}>
                {translations.inicio}
              </Button>,
            ]}
          />
        )}
      </Spin>
    </div>
  );
};

export default CreateSignature;
