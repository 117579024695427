import {
  Button,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Select,
} from "antd";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";

import "./Contacts.scss";
import { InvestorAPI } from "../../../Api/InvestorAPI";
import { useContacts } from "../../../Hooks/useContacts";
import { ContactsAPI } from "../../../Api/ContactsAPI";
import FormLayout from "../../FormLayout/FormLayout";
import { DistributorsAPI } from "../../../Api/DistributorsAPI";

const Contacts = ({
  baseUrl,
  translations,
  unlockNextStep,
  getAuthToken,
}: any) => {
  const investorAPI = InvestorAPI(baseUrl, getAuthToken);
  const contactAPI = ContactsAPI(baseUrl, getAuthToken);
  const distributorsAPI = DistributorsAPI(baseUrl, getAuthToken);

  const {
    loading,
    errors,
    contacts,
    addRow,
    handleChangeData,
    deleteRow,
    saveData,
	oldContacts  
} = useContacts({
    translations,
    investorAPI,
    contactAPI,
    distributorsAPI,
    unlockNextStep,
  });

  return (
    <FormLayout
      translations={translations}
      loading={loading}
      title={translations.contactosInversor}
      onClick={saveData}
	  nextStep={unlockNextStep}
	  enableNextStep={true}
    >
		<Button type="default" className="save-btn" onClick={addRow}>
			<PlusCircleOutlined />
			{translations.masContacto}
		</Button>
      {oldContacts.map((oldContact: any, i: number) => (
        <Row gutter={[16, 16]} align="bottom" key={i}>
          <Col xs={24} md={12} lg={12} >
            <label> {translations.nombreFirmante}</label>
            <Input
              value={oldContact.name}
			  disabled
            />
          </Col>
          <Col xs={24} md={12} lg={12} >
            <label> {translations.nombreFirmante}</label>
            <Input
              value={oldContact.lastName}
			  disabled
            />
          </Col>
          <Col xs={24} md={12} lg={6} >
            <label> {translations.type}</label>
            <Input
              value={translations[oldContact.type]}
			  disabled
            />
          </Col>
          <Col xs={24} md={12} lg={6} >
            <label> {translations.email}</label>
            <Input
              value={oldContact.emailContact ?? oldContact.email}
			  disabled
            />
          </Col>
          <Col xs={24} md={12} lg={6} >
            <label> {translations.prefix}</label>
            <Input
              value={oldContact.phonePrefix}
			  disabled
            />
          </Col>
          <Col xs={24} md={12} lg={6} >
            <label> {translations.numeroTelefonoFisica}</label>
            <Input
              value={oldContact.phoneNumber}
			  disabled
            />
          </Col>
		  <Divider />
		</Row>
      ))}
      {contacts.map((contact: any, i: number) => (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={12} >
            <label className="required"> {translations.nombreFirmante}</label>
            <Input
              type="text"
              name="name"
              value={contact.name}
              onChange={(e) => handleChangeData(e, i)}
              status={errors[i].name ? "error" : ""}
              placeholder={translations.nombreFirmante}
            />
            <span className={`error ${errors[i].name ? "show" : null}`}>
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={12} lg={12} >
            <label className="required">
              {translations.apellidosFirmante}
            </label>
            <Input
              type="text"
              name="lastName"
              value={contact.lastName}
              onChange={(e) => handleChangeData(e, i)}
              status={errors[i].lastName ? "error" : ""}
              placeholder={translations.apellidosFirmante}
            />
            <span
              className={`error ${errors[i].lastName ? "show" : null}`}
            >
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={7} lg={7} >
            <label className="required"> {translations.emailFirmante}</label>
            <Input
              type="email"
              name="email"
              value={contact.email}
              onChange={(e) => handleChangeData(e, i)}
              status={errors[i].email ? "error" : ""}
              placeholder={translations.emailFirmante}
            />
            <span className={`error ${errors[i].email ? "show" : null}`}>
              {translations.completeEmailValido}
            </span>
          </Col>
          <Col xs={24} md={7} lg={7} >
            <label className="required">
              {translations.prefix}
            </label>
            <Input
              type="text"
              name="phonePrefix"
              value={contact.phonePrefix}
              onChange={(e) => handleChangeData(e, i)}
              status={errors[i].phonePrefix ? "error" : ""}
              placeholder={translations.prefix}
            />
            <span className={`error ${errors[i].phonePrefix ? "show" : null}`}>
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={7} lg={7} >
            <label className="required">
              {translations.numeroTelefonoFisica}
            </label>
            <Input
              type="text"
              name="phoneNumber"
              value={contact.phoneNumber}
              onChange={(e) => handleChangeData(e, i)}
              status={errors[i].phoneNumber ? "error" : ""}
              placeholder={translations.numeroTelefonoFisica}
            />
            <span className={`error ${errors[i].phoneNumber ? "show" : null}`}>
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={3} lg={3} >
            <Popconfirm
              title={translations.eliminarContacto}
              okText={translations.si}
              placement="left"

              cancelText={translations.no}
              onConfirm={() => deleteRow(i)}
            >
              <Button danger style={{marginTop: '22px'}}>{translations.delete}</Button>
            </Popconfirm>
          </Col>
		  <Divider />
		</Row>
      ))}
    </FormLayout>
  );
};

export default Contacts;
