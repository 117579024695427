import { Select } from "antd";
import { useEffect } from "react";
import { useGraphic } from "../../../Hooks/useGraphic";
import NumberFormat from "react-number-format";
import PieChart, {
  Series,
  Label,
  Size,
  Legend,
} from "devextreme-react/pie-chart";
import PercentItem from "./PercentItem";
import { ISidebar } from "./ISidebar";

const { Option } = Select;

const Sidebar = (props: ISidebar) => {
  const {
    userId,
    userIdInstitution,
    translations,
    institutions,
    contracts,
    handleChangeFilteredContracts,
    userEmail
  } = props;
  const {
    getContractValues,
    pieData,
    percent,
    tabItems,
    onChangeInstitution,
    selectedInstitution,
    setSelectedInstitution,
    onChangeVehicle,
    selectedVehicle,
  } = useGraphic({
    userIdInstitution,
    institutions,
    contracts,
    handleChangeFilteredContracts,
  });

  useEffect(() => {
    if (userId > 0) {
      getContractValues(userIdInstitution, 0);
      setSelectedInstitution(userIdInstitution);
    }
  }, [institutions]);

  let blackLogo = '';
  if (userEmail.includes('santander')) {
    blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/SantanderBlack.png';
  } else if(userEmail.includes('singular')){
    blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/SingularLogoBlack.png';
  }else{
    blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/blackLogo.png';
  }
  
  return (
    <div className="homepage-sidebar">
      <div className="homepage-sidebar-logo">
          <img
          src={blackLogo}
          alt="colocador-logo"
        />
      </div>
      <div className="distributor-select">
        <span>Distribuidor:</span>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          value={selectedInstitution}
          bordered={false}
          disabled={institutions.length === 1}
          onChange={(e: number) => onChangeInstitution(e)}
        >
          {institutions.map((x: any) => (
            <Option value={x.idInstitution}>{x.institutionLong}</Option>
          ))}
        </Select>
      </div>
      <div className="vehicle-table">
        <div className="vehicle-table-header">
          <span>{translations.vehiculo}</span>
          <span>{translations.maximoDisponible}</span>
        </div>
        <div className="vehicle-table-body">
          {tabItems.map((tx: any, index: number) => (
            <button
              onClick={() => onChangeVehicle(selectedInstitution, index)}
              className={`vehicle-table-body-row ${
                selectedVehicle === index ? "selectedInstitution-pin" : ""
              }`}
            >
              <span>{tx.vehicleShortName}</span>
              <span>
                {
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    value={tx.maxAvailable}
                    displayType={"text"}
                    prefix={"€"}
                  />
                }
              </span>
            </button>
          ))}
        </div>
      </div>
      <div className="sidebar-pie">
        {pieData ? (
          <PieChart
            dataSource={pieData}
            palette={['#6B6B6B', '#c9c9c9', '#8fac10']}
          >
            <Series argumentField="type" valueField="value">
              <Label visible={false}/>
            </Series>
            <Legend visible={false}/>
            <Size width={310} height={200} />
          </PieChart>
        ) : null}
      </div>
      <div className="percent-list">
            <PercentItem 
                color="grey-description"
                amount={tabItems[selectedVehicle]?.reserved !== null ? tabItems[selectedVehicle]?.reserved : 0}
                percent={percent?.pre}
                description={translations.totalProceso}
            />
            <PercentItem 
                color="dark-grey-description"
                amount={tabItems[selectedVehicle]?.confirmed !== null ? tabItems[selectedVehicle]?.confirmed : 0}
                percent={percent?.confirmed}
                description={translations.totalFirmado}
            />
            <PercentItem 
                color="green-description"
                amount={tabItems[selectedVehicle]?.available !== null ? tabItems[selectedVehicle]?.available : 0}
                percent={percent?.disp}
                description={translations.totalDisponible}
            />
      </div>
    </div>
  );
};

export default Sidebar;
