import { Alert, Checkbox, Col, Divider, Input, Radio, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLegalInvestorStatus } from '../../../Hooks/useLegalInvestorStatus';
import { ILegalInvestorStatus } from './ILegalInvestorStatus';
import './LegalInvestorStatus.scss';
import FormLayout from '../../FormLayout/FormLayout';
import { LegalInvestorStatusAPI } from '../../../Api/LegalInvestorStatusAPI';

const LegalstatusData = ({
	baseUrl,
	translations,
	getAuthToken,
	unlockNextStep
}: ILegalInvestorStatus) => {

	const legalInvestorStatusAPI = LegalInvestorStatusAPI(baseUrl, getAuthToken);
	const {
		loading,
		statusData,
		visiblePoints,
		handleCheckedData,
		handleChangeData,
		saveData,
		status1Errors
	} = useLegalInvestorStatus({
		translations,
		legalInvestorStatusAPI,
		unlockNextStep
	});

	return (
		<FormLayout 
			translations={translations}
			loading={loading}
			title={translations.estatusInversor}
			subTitle={translations.encabezadoEstatus}
			onClick={saveData}
			disabledSaveButton={sessionStorage.isEditing === "false"}
			nextStep={unlockNextStep}
			enableNextStep={statusData.id > 0 ? true : false}
		>	
		<div className="legalInvestorStatus">
			<Row>
				<Col xs={24} md={24} lg={24} className="titles">
					{translations.tituloStatusUnoJuridica}
				</Col>
			</Row>
			<Row className="titles">
				<Radio.Group
					className="radio-group"
					name="status1"
					value={statusData.status1}
					onChange={handleChangeData}
				>
					<Radio className="radio-option" value={1}> {translations.opcionAStatusUno}</Radio>
					<Radio className="radio-option" value={2}> {translations.opcionBStatusUno}</Radio>
					<Radio className="radio-option" value={3}> {translations.opcionCStatusUno}</Radio>
					<Radio className="radio-option" value={4}> {translations.opcionDStatusUno}</Radio>
					<Radio className="radio-option" value={5}> {translations.opcionEStatusUno}</Radio>
					<Radio className="radio-option" value={6}> {translations.opcionFStatusUno}</Radio>
					<Radio className="radio-option" value={7}> {translations.opcionGStatusUno}</Radio>
					<Radio className="radio-option" value={8}> {translations.opcionHStatusUno}</Radio>
					<Radio className="radio-option" value={9}> {translations.opcionIStatusUno}</Radio>
					<Radio className="radio-option" value={10}> {translations.opcionJStatusUno}</Radio>
					<Radio className="radio-option" value={11}> {translations.opcionKStatusUno}
						<Row>
						<Col xs={24} md={24} lg={24} style={{ textAlign: 'justify' }}>
							<br />
							{translations.parrafoStatusUno}
						</Col>
						</Row>
					</Radio>
					<Radio className="radio-option" value={12}>
						{translations.opcionLStatusUno}
						<Row>
							<span className={`error ${status1Errors ? 'show' : null}`}>{translations.errorStatusUno}</span>
							<Col xs={24} md={24} lg={24} style={{ background: '#F2F2F2', width: '100%' }} >
								<Checkbox
									name="status1_12_1"
									checked={statusData?.status1_12_1}
									onChange={handleCheckedData}
									disabled={statusData.status1 !== 12}
								>
									{translations.opcionLUno}
								</Checkbox>
							</Col>
							<Col xs={24} md={24} lg={24} >
								<Checkbox
									name="status1_12_2"
									checked={statusData?.status1_12_2}
									onChange={handleCheckedData}
									disabled={statusData.status1 !== 12}
								>
									{translations.opcionLDos}
								</Checkbox>
							</Col>
							<Col xs={24} md={24} lg={24} style={{ background: '#F2F2F2' }}>
								<Checkbox
									name="status1_12_3"
									checked={statusData?.status1_12_3}
									onChange={handleCheckedData}
									disabled={statusData.status1 !== 12}
								>
									{translations.opcionLTres}
								</Checkbox>
							</Col>
						</Row>
					</Radio>
					<Radio className="radio-option" value={13}> {translations.ningunaAnterior}</Radio>
				</Radio.Group>
				{statusData.status1 === 12 ?
					<Alert message="Aporte documentación que justifique opción/opciones seleccionadas o en su defecto puede clasificarse como inversor minorista" banner />
					: null
				}
			</Row>
			<Row>
				<Checkbox
					name="status2"
					checked={statusData.status2}
					onChange={handleCheckedData}
				>
					{translations.opcionMStatusUno}
				</Checkbox>
			</Row>
			{/* PUNTO 2 */}
			{visiblePoints.point2 ?
				<>
					<Divider />
					<Row>
						<Col xs={24} md={24} lg={24} className="titles">
							{translations.tituloStatusDosJuridica}
						</Col>
					</Row>
					<Row className="titles question-two">
						<Checkbox
							className="question-two-checkbox"
							name="status3_1"
							checked={statusData.status3_1}
							onChange={handleCheckedData}
						>
							{translations.opcionAStatusDos}
						</Checkbox>
						<Checkbox
							name="status3_2"
							className="question-two-checkbox"
							checked={statusData.status3_2}
							onChange={handleCheckedData}
						>
							{translations.opcionBStatusDos}
						</Checkbox>
						<Checkbox
							className="question-two-checkbox"
							name="status3_3"
							checked={statusData.status3_3}
							onChange={handleCheckedData}
						>
							{translations.opcionCStatusDos}
						</Checkbox>
					</Row>
					{(statusData.status3_1 && statusData.status3_2) ||
					(statusData.status3_2 && statusData.status3_3) ||
					(statusData.status3_1 && statusData.status3_3) 
					?
						<Alert message="Aporte documentación que justifique opción/opciones seleccionadas o en su defecto puede clasificarse como inversor minorista" banner />
						: null
					}
				</> : null
			}
		
			{/* PUNTO 3 */}
			{visiblePoints.point3 ?
				<>
					<Divider />
					<span className="titles" >
						{translations.explicacionStatusDos}<br /><br />
					</span>
					<Row>
						<Col xs={24} md={24} lg={24} className="titles" >
							3. {translations.tituloStatusTres}
						</Col>
					</Row>
					<Row className="titles">
						<Radio.Group
							className="radio-group"
							name="status4"
							value={statusData.status4}
							onChange={handleChangeData}
						>
							<Radio className="radio-option" value={1}>{translations.opcionAStatusTres}</Radio><br />
							<Radio className="radio-option" value={2}> {translations.opcionBStatusTres}</Radio><br />
							<Radio className="radio-option" value={3}> {translations.opcionCStatusTres}</Radio><br />
							<Radio className="radio-option" value={4}> {translations.ningunaAnterior}</Radio><br />
						</Radio.Group>
					</Row>
					<Row>
						<Col xs={24} md={24} lg={24} className="titles">
							{translations.tituloExplicacion}
							<Input
								name="status5"
								value={statusData.status5}
								onChange={handleChangeData}
							/>
						</Col>
					</Row>
				</> : null
			}

			{/* PUNTO 4 */}
			{visiblePoints.point4 &&
				<>
					<Divider />
					<Row className="titles">
						<Checkbox
							name="status6"
							checked={statusData.status6}
							onChange={handleCheckedData}
						>
							<span> 4. {translations.tituloStatusCuatro} </span> 
							<Tooltip placement="topLeft" title={translations.tooltipStatusCuatro}>
								<InfoCircleOutlined className="tooltip" />
							</Tooltip>
						</Checkbox>
					</Row>
				</>
			}
			<Divider />
			<Row className="titles">
				<Checkbox
					name="status7"
					checked={statusData.status7}
					onChange={handleCheckedData}
				>
					<span> 5. {translations.tituloStatusCinco} </span> 
				</Checkbox>
			</Row>
			<Row>
				<Col xs={24} md={24} lg={24} className="titles">
					{translations.parrafoFinal}
				</Col>
			</Row>
		</div>
		</FormLayout>
	);
};

export default LegalstatusData;  
