import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { message, notification } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { IUseSuitabilityTest } from "./IUseSuitabilityTest";
import { SuitabilityTestInvestor } from "../Models/SuitabilityTestInvestor";
import { SuitabilityTestResult } from "../Models/SuitabilityTestResult";

export const useSuitabiltyTest = ({
  suitabilityTestAPI,
  unlockNextStep,
  navigate,
  investorAPI,
  translations,
}: IUseSuitabilityTest) => {
  const [searchParams, _] = useSearchParams();
  const [idContract, setIdContract] = useState<number>(
    Number(searchParams.get("ContractId"))
  );
  const [investorInfo, setInvestorInfo] = useState<SuitabilityTestInvestor>({
    idInvestorTestFiller: undefined,
    name: undefined,
    surname: undefined,
    birthDate: undefined,
    dni: undefined,
  });

  const [isSuitabilityDone, setIsSuitabilityDone] = useState<boolean>(false);

  const [testResponses, setTestResponses] = useState<SuitabilityTestResult>({
    trainingMaster: false,
    trainingUniversity: false,
    trainingCertification: false,
    trainingCourses: false,
    trainingNone: false,
    positionInFinancial: null,
    profitabilityAndRisk: null,
    liquidityRisk: null,
    marketRisk: null,
    ventureCapitalGoal: null,
    ventureCapitalFirms: null,
    bankDepositsOps: null,
    bankDepositsVol: null,
    fixedIncomeOps: null,
    fixedIncomeVol: null,
    variableIncomeOps: null,
    variableIncomeVol: null,
    otcOps: null,
    otcVol: null,
    iiC_ETFOps: null,
    iiC_ETFVol: null,
    altInvestmentVehiclesOps: null,
    altInvestmentVehiclesVol: null,
    capitalCallsOps: null,
    capitalCallsVol: null,
    investmentTime: null,
  });
  const [errors, setErrors] = useState<any>({
    question1: false,
    positionInFinancial: false,
    profitabilityAndRisk: false,
    liquidityRisk: false,
    marketRisk: false,
    ventureCapitalGoal: false,
    ventureCapitalFirms: false,
    bankDepositsOps: false,
    bankDepositsVol: false,
    fixedIncomeOps: false,
    fixedIncomeVol: false,
    variableIncomeOps: false,
    variableIncomeVol: false,
    otcOps: false,
    otcVol: false,
    iiC_ETFOps: false,
    iiC_ETFVol: false,
    altInvestmentVehiclesOps: false,
    altInvestmentVehiclesVol: false,
    capitalCallsOps: false,
    capitalCallsVol: false,
    investmentTime: false,
  });
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (idContract && idContract > 0) {
      suitabilityTestAPI
        .getSuitabilityTest(idContract)
        .then(async (res: any) => {
          if (res.success && res.data?.idContract) {
            setIsSuitabilityDone(true);
            setTestResponses(res.data);
          } 
    		await getInvestorData(idContract);
        });
    }
  }, [idContract]);

  const getInvestorData = async (contractId: number) => {
    await investorAPI.getInvestors(contractId).then((res: any) => {
      if (res.success && res.data.length > 0) {
        setInvestorInfo({
          name: res.data[0].name,
          surname: res.data[0].lastName,
          birthDate: res.data[0].birthDate
            ? moment(res.data[0].birthDate).format("YYYY-MM-DD")
            : undefined,
          dni: res.data[0].dni || res.data[0].nif || res.data[0].passport || res.data[0].residenceCard,
          idInvestorTestFiller: res.data[0].id,
        });
      }
    });
  };

  const isTestDataValid = () => {
    let isOK = true;
    if (!isSuitabilityDone) {
      if (
        !testResponses.trainingMaster &&
        !testResponses.trainingUniversity &&
        !testResponses.trainingCertification &&
        !testResponses.trainingCourses &&
        !testResponses.trainingNone
      ) {
        setErrors((prevState: any) => ({ ...prevState!, question1: true }));
        isOK = false;
      }
    }

    if (!isSuitabilityDone) {
      for (let key in testResponses) {
        if (testResponses[key] === null || testResponses[key] === undefined) {
          setErrors((prevState: any) => ({ ...prevState!, [key]: true }));
          isOK = false;
        }
      }
      return isOK;
    }
  };

  const saveTest = () => {
    let result = isTestDataValid();
    if (result) {
      let data = {
        ...testResponses,
        idContract,
        idInvestorTestFiller: investorInfo.idInvestorTestFiller,
        suitable: false,
      };
      suitabilityTestAPI.saveSuitabilityTest(data).then((res: any) => {
        if (res.success) {
          if (res.data) {
            toNextStep();
            message.success(translations.suitabilityTestSent);
          } else {
            setIsOpenConfirmModal(true);
          }
        }
      });
    } else {
      notification.error({
        message: "Debe rellenar todos los campos del formulario",
      });
    }
  };

  const onQuestionAnswered = (question: string) => (response: any) => {
    if (question.startsWith("training")) {
      setErrors((prevState: any) => ({ ...prevState!, question1: false }));
    } else {
      setErrors((prevState: any) => ({ ...prevState!, [question]: false }));
    }
    setTestResponses((prevState: SuitabilityTestResult | undefined) => ({
      ...prevState!,
      [question]: response,
    }));
  };

  const onCancelContinue = () => {
    setIsOpenConfirmModal(false);
    navigate("/");
  };

  const toNextStep = () => {
    setIsOpenConfirmModal(false);
    unlockNextStep();
  };

  return {
    isSuitabilityDone,
    errors,
    investorInfo,
    testResponses,
    isOpenConfirmModal,
    onQuestionAnswered,
    saveTest,
    onCancelContinue,
    toNextStep,
  };
};
