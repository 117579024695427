import { Col, Input, Row, Select } from 'antd';

import { useDistributorData } from '../../../Hooks/useDistributorData';

import { IDistributorData } from './IDistributorData';

import './DistributorData.scss';
import FormLayout from '../../FormLayout/FormLayout';
import { DistributorsAPI } from '../../../Api/DistributorsAPI';

const { Option } = Select;

const DistributorData = ({
	baseUrl,
	translations,
	getAuthToken,
	unlockNextStep,
}: IDistributorData) => {

	const distributorsAPI = DistributorsAPI(baseUrl, getAuthToken);
	const {
		loading,
		errors,
		distributorData,
		handleChangeData,
		saveData,
	} = useDistributorData({
		translations,
		distributorsAPI,
		unlockNextStep
	});

	return (
		<FormLayout 
			translations={translations}
			loading={loading}
			title={translations.datosDistribuidor}
			subTitle={translations.datosCumplimenta}
			onClick={saveData}
			nextStep={unlockNextStep}
			enableNextStep={(distributorData.id && distributorData.id > 0) ? true : false}
		>	
			<Row gutter={[16, 16]}>
				<Col xs={24} md={8} lg={8}>
					<label className="required"> {translations.nombreFirmante}</label>
					<Input
						type="text"
						name="name"
						value={distributorData.name}
						onChange={handleChangeData}
						placeholder={translations.nombreFirmante}
					/>
					<span className={`error ${errors.name ? 'show' : null}`}>{translations.completeNombreFirmante}</span>
				</Col>
				<Col xs={24} md={8} lg={8} >
					<label className="required"> {translations.apellidosFirmante}</label>
					<Input
						type="text"
						name="lastName"
						value={distributorData.lastName}
						onChange={handleChangeData}
						placeholder={translations.apellidosFirmante}
					/>
					<span className={`error ${errors.lastName ? 'show' : null}`}>{translations.completeApellidoFirmante}</span>
				</Col>
				<Col xs={24} md={8} lg={8} >
					<label className="required"> {translations.tipo}</label>
					<Select
						className="input-field"
						value={distributorData.type}
						onChange={(value: string) => handleChangeData({ target: { name: 'type', value}})}
					>
						<Option value={"distributor"}>{translations.distribuidor}</Option>
						<Option value={"investor"}>{translations.inversor}</Option>
					</Select>
				</Col>
				<Col xs={24} md={8} lg={8} >
					<label className="required"> {translations.emailFirmante}</label>
					<Input
						type="email"
						name="email"
						value={distributorData.email}
						onChange={handleChangeData}
						placeholder={translations.emailFirmante}
					/>
					<span className={`error ${errors.email ? 'show' : null}`}>{translations.completeEmailFirmante}</span>
				</Col>
				<Col xs={24} md={8} lg={8} >
					<label className="required"> {translations.prefix}</label>
					<Input
						type="text"
						name="phonePrefix"
						value={distributorData.phonePrefix}
						onChange={handleChangeData}
						placeholder="+34"
					/>
					<span className={`error ${errors.phonePrefix ? 'show' : null}`}>{translations.completeTelefonoFirmante}</span>
				</Col>
				<Col xs={24} md={8} lg={8} >
					<label className="required"> {translations.telefonoContacto}</label>
					<Input
						type="text"
						name="phoneNumber"
						value={distributorData.phoneNumber}
						onChange={handleChangeData}
						placeholder="000 000 000"
					/>
					<span className={`error ${errors.phoneNumber ? 'show' : null}`}>{translations.completeTelefonoFirmante}</span>
				</Col>
			</Row>
			<h3 className="notificacions-msg">{translations.notifications}</h3>
			<Row gutter={[16, 16]}>
				<Col xs={24} md={16} lg={16} >
					<span>{translations.notificationsMessage}</span>
				</Col>
				<Col xs={24} md={8} lg={8} >
					<Input.TextArea
						autoSize
						name="emailToNotify"
						value={distributorData.emailToNotify}
						onChange={handleChangeData}
						placeholder={translations.emailFirmante}
					/>
					<span className="semi-colon-msg">{translations.notificationsMessage2}</span>
				</Col>
				
			</Row>
		</FormLayout>
	);
};

export default DistributorData;
