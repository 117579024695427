import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import { IUseTaxHolders } from "./IUseTaxHolders";
import { TaxHolder } from "../Models/TaxHolders";
import { emptyTaxHolder, emptyTaxHolderErrors } from "../Assets/Data/EmptyValues";

export const useTaxHolders = ({
	taxHoldersAPI,
	translations,
	unlockNextStep
}: IUseTaxHolders) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [contractId, setContractId] = useState<number>(0);
	const [taxHolders, setTaxHolders] = useState<TaxHolder[]>([{...emptyTaxHolder}]);
	const [errors, setErrors] = useState<any[]>([{...emptyTaxHolderErrors}]);

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
			setContractId(Number(searchParams.get('ContractId')));
		}
	}, []);

	const addRow = () => {
		let el: TaxHolder = { ...emptyTaxHolder };
		setTaxHolders(oldArray => [...oldArray, el]);
		setErrors(oldArray => [...oldArray, {...emptyTaxHolderErrors}]);
	};

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await taxHoldersAPI.getTaxHolders(ContractId).then((res: any) => {
			if (res.success) {
				if(res.data.length > 0){
					setTaxHolders(res.data);
					setErrors(res.data.map(() => ({...emptyTaxHolderErrors})));

				}else{
					setTaxHolders([{...emptyTaxHolder}]);
					setErrors([{...emptyTaxHolderErrors}]);
				}
			}
			setLoading(false);
		}).catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
	};

	const handleChangeData = (e: any, index: number) => {
		const { name, value } = e.target;
		let array = [...taxHolders as any];
		array[index][name] = value;
		setTaxHolders(array);

		const newErrors = [...errors];
		newErrors[index][name] = false;
		setErrors(newErrors);
	};

	const validateData = (data: TaxHolder, index: number) => {
		let isOK = true;
		const newErrors = [...errors];
		if (!data.name) {
			newErrors[index].name = true;
			isOK = false;
		}
		if (!data.lastName) {
			newErrors[index].lastName = true;
			isOK = false;
		}
		if (!data.participationPercentage) {
			newErrors[index].participationPercentage = true;
			isOK = false;
		}
		if(!data.taxResidency){
			newErrors[index].taxResidency = true;
			isOK = false;
		}
		setErrors(newErrors);
		return isOK;
	}

	const postTaxHolders = async (newTaxHolders: TaxHolder[]) => {
		await taxHoldersAPI.saveTaxHolders(newTaxHolders)
		.then((res: any) => {
			if (res.success) {
				unlockNextStep();
				message.success(translations.datosGuardados);
			} else {
				message.error(translations.messageError);
			}
		});
	}

	const updateTaxHolders = async (updatedTaxHolders: TaxHolder[]) => {
		await taxHoldersAPI.updateTaxHolders(updatedTaxHolders)
		.then((res: any) => {
			if (res.success) {
				message.success(translations.datosGuardados);
			} else {
				message.error(translations.messageError);
			}
		});
	}

	const saveData = async() => {
		setLoading(true);
		let isDataOK = true;
		taxHolders.map((representative: TaxHolder, i) => {
			const isDataValid = validateData(representative, i);
			if (isDataOK && !isDataValid) {
				isDataOK = false;
			} else {
				representative.idContract = contractId;
			}
			return representative;
		});
		let newTaxHolders = taxHolders.filter((x: any) => !x.id);
		let updatedTaxHolders = taxHolders.filter((x: any) => x.id > 0);

		if (isDataOK) {
			if(updatedTaxHolders.length > 0){
				await updateTaxHolders(updatedTaxHolders);
			}
			if (newTaxHolders.length > 0) {
				await postTaxHolders(newTaxHolders);
			}
		} 
		setLoading(false);
	};

	const deleteRow = async (i: number) => {
		if(taxHolders[i].id > 0){
			await taxHoldersAPI.deleteTaxHolders(taxHolders[i].id)
				.then((res: any) => {
					if(res.success){
						setTaxHolders((prevState: any) => [...prevState.filter((_: any, index: number) => index !== i)]);
						setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
					}else{
						message.error(translations.messageError);
					}
				});
		}else{
			setTaxHolders((prevState: any) => [...prevState.filter((_: any, index: number) => index !== i)]);
			setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
		}
	};

	return {
		loading,
		taxHolders,
		addRow,
		handleChangeData,
		saveData,
		deleteRow,
		errors
	};
};