import NumberFormat from "react-number-format";
import { IPercentItem } from "./IPercentItem";

const PercentItem = (props: IPercentItem) => {
  const { color, amount, percent, description } = props;
  return (
    <div className="percent-item">
      <p className="percent-value">
        {percent === 'NaN' ? '0.00' : percent} <span>%</span>
      </p>
      <div className={`percent-description ${color}`}>
        <span>{description}</span>
        <p>
          <NumberFormat
            thousandSeparator={"."}
            decimalSeparator={","}
            value={amount}
            displayType={"text"}
            prefix={"€"}
          />
        </p>
      </div>
    </div>
  );
};

export default PercentItem;
