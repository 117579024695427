import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

import { SuitabilityTest } from '../Models/SuitabilityTest';

export const SuitabilityTestAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

	const getSuitabilityTest = (idContract: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/SuitabilityTest`;
        return api.get(serviceUrl, { params: { idContract } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const saveSuitabilityTest = (data: SuitabilityTest) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/SuitabilityTest`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        getSuitabilityTest,
        saveSuitabilityTest
    };
};
