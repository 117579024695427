import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const CrsAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const saveCRS = (data: any) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/CRS/SaveCRS`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateCRS = (data: any) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/CRS/UpdateCRS`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
	const getCRS = (contractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/CRS/GetCRS`;
        return api.get(serviceUrl, { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        saveCRS,
        updateCRS,
        getCRS,
    };
};