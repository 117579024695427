import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";

import { emptyLegalInvestorStatus } from "../Assets/Data/EmptyValues";
import { LegalInvestorStatus } from "../Models/InvestorStatus";
import { IUseLegalInvestorStatus } from "./IUseLegalInvestorStatus";

export const useLegalInvestorStatus = ({
	translations,
	legalInvestorStatusAPI,
	unlockNextStep
}: IUseLegalInvestorStatus) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [statusData, setStatusData] = useState<LegalInvestorStatus>(emptyLegalInvestorStatus);;
	const [status1Errors, setStatus1Errors] = useState<boolean>(false);

	const [visiblePoints, setVisiblePoints] = useState({
		point1: true,
		point2: true,
		point3: true,
		point4: true,
		point5: true
	});
	
	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
		}
	}, [translations]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await legalInvestorStatusAPI.getLegalStatus(ContractId).then((res: any) => {
			if (res.success) {
				if(res.data){
					setStatusData(res.data);
				}else{
					setStatusData({...emptyLegalInvestorStatus, idContract: ContractId});
				}
			}
			setLoading(false);
		}).catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
	};

	const handleChangeData = (e: any) => {
		const { name, value } = e.target;
		setStatusData((prevState: LegalInvestorStatus) => ({
			...prevState,
			[name]: value
		}));
		validateForm(e.target.name, value);
	};

	const handleCheckedData = (e: any) => {
		const { name } = e.target;
		setStatusData((prevState: LegalInvestorStatus) => ({
			...prevState,
			[name]: e.target.checked
		}));
		validateForm(e.target.name, e.target.checked);
	};

	const validateForm = (field: string, value: any) => {
		let status: any = statusData;
		status[field] = value
		if (field === "status1") {
			if (status.status1 === 13) {
				setStatusData((prevState: LegalInvestorStatus) => ({
					...prevState,
					 status2: true
				}));
/* 				setVisiblePoints({
					point1: true,
					point2: true,
					point3: true,
					point4: true,
					point5: true
				}); */
			} else if(status.status1 !== 12){
				setStatusData((prevState: LegalInvestorStatus) => ({
					...prevState,
					status2: false,
					status1_12_1: false,
					status1_12_2: false,
					status1_12_3: false,
					status3_1: false,
					status3_2: false,
					status3_3: false,
					status4: 0,
					status5: "",
				}));
			} else{
/* 				setVisiblePoints({
					point1: true,
					point2: false,
					point3: false,
					point4: false,
					point5: true
				}); */
				setStatusData((prevState: LegalInvestorStatus) => ({
					...prevState,
					status2: false,
					status3_1: false,
					status3_2: false,
					status3_3: false,
					status4: 0,
					status5: "",
				}));

			}
		} else if(field === "status3_1" || field === "status3_2"  || field === "status3_3") {
			let count = 0;

			if (status.status3_1) count++;
			if (status.status3_2) count++;
			if (status.status3_3) count++;

			if(count >= 2) {
/* 				setVisiblePoints({
					point1: true,
					point2: true,
					point3: false,
					point4: false,
					point5: true
				});
				setStatusData((prevState: LegalInvestorStatus) => ({
					...prevState,
					status4: 4,
					status5: "",
					status6: false
				})); */
			} else {
/* 				setVisiblePoints({
					point1: true,
					point2: true,
					point3: true,
					point4: true,
					point5: true
				}); */
			}
		} else if(field === "status4") {
			if (status.status4 !== 4) {
/* 				setVisiblePoints({
					point1: true,
					point2: true,
					point3: true,
					point4: false,
					point5: true
				}); */
/* 				setStatusData((prevState: LegalInvestorStatus) => ({
					...prevState,
					status6: false
				})); */
			} else {
/* 				setVisiblePoints({
					point1: true,
					point2: true,
					point3: true,
					point4: true,
					point5: true
				}); */
			}
		}
	};

	const postData = async() => {	
		await legalInvestorStatusAPI.saveLegalStatus(statusData).then((res: any) => {
			if(res.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
			}else{
				message.error(translations.errorGuardado);
			}
			setLoading(false);
		}
		).catch((err: any) => {
			message.error(translations.errorGuardado);
			setLoading(false);
		});
	}

	const updateData = async() => {
		await legalInvestorStatusAPI.updateLegalStatus(statusData).then((res: any) => {
			if(res.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
			}else{
				message.error(translations.errorGuardado);
			}
			setLoading(false);
		}
		).catch((err: any) => {
			message.error(translations.errorGuardado);
			setLoading(false);
		});
	}

	const saveData = async() => {
		if(statusData.status1 === 12){
			if((!statusData.status1_12_1 && !statusData.status1_12_2) ||
				(!statusData.status1_12_2 && !statusData.status1_12_3) ||
				(!statusData.status1_12_3 && !statusData.status1_12_2) 
			){
				setStatus1Errors(true)
				return;
			}else{
				setStatus1Errors(false)
			}
		}else{
			setStatus1Errors(false)
		}
		setLoading(true);
		if (statusData?.id) {
			updateData();
		}
		else {
			postData();
		}
	}


	return {
		loading,
		visiblePoints,
		handleChangeData,
		handleCheckedData,
		saveData,
		statusData,
		status1Errors
	};
};