import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";

import { IUsePhysicalInvestorStatus } from "./IUsePhysicalInvestorStatus";
import { PhysicalInvestorStatus } from "../Models/InvestorStatus";
import { emptyInvestorStatus } from "../Assets/Data/EmptyValues";

export const usePhysicalInvestorStatus = ({
	translations,
	physicalInvestorStatusAPI,
	unlockNextStep
}: IUsePhysicalInvestorStatus) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [statusData, setStatusData] = useState<PhysicalInvestorStatus>(emptyInvestorStatus);;
	const [visiblePoints, setVisiblePoints] = useState({
		point2: true,
		point3: true,
		point4: true,
	});

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
		}
	}, [translations]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await physicalInvestorStatusAPI.getPhysicalStatus(ContractId).then((res: any) => {
			if (res.success) {
				if(res.data){
					setStatusData(res.data);
				}else{
					setStatusData({...emptyInvestorStatus, idContract: ContractId});
				}
			}
			setLoading(false);
		}).catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
	};

	const handleChangeData = (e: any) => {
		const { name, value } = e.target;
		setStatusData((prevState: any) => ({
			...prevState,
			[name]: value
		}));
		validateForm(e.target.name, value);
	};

	const handleCheckedData = (e: any) => {
		const { name } = e.target;
		setStatusData((prevState: any) => ({
			...prevState,
			[name]: e.target.checked
		}));
		validateForm(e.target.name, e.target.checked);
	};

	const validateForm = (field: string, value: any) => {
		let status: any = {...statusData};
		status[field] = value
		if (field === "status1_1" || field === "status1_2" || field === "status1_3") {
			let count = 0;

 			if (status.status1_1) count++;
			if (status.status1_2) count++;
			if (status.status1_3) count++;

			/* if (count >= 2) {
				setVisiblePoints({
					point2: true,
					point3: false,
					point4: false,
				});
				setStatusData(prevState => ({
					...prevState,
					status2: 4,
					status3: '',
					status4: false

				}));
			} else {
				setVisiblePoints({
					point2: true,
					point3: true,
					point4: true,
				});
			} */
		} else if (field === "status2") {
			if (value !== 4) {
				/* setVisiblePoints({
					point2: true,
					point3: true,
					point4: false,
				}); */
				setStatusData((prevState: any) => ({
					...prevState,
					status4: false
				}));
			} else {
				/* setVisiblePoints({
					point2: true,
					point3: true,
					point4: true,
				}); */
			}
		}
	};

	const postData = async() => {	
		await physicalInvestorStatusAPI.savePhysicalStatus(statusData).then((res: any) => {
			if(res.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
			}else{
				message.error(translations.errorGuardado);
			}
			setLoading(false);
		}
		).catch((err: any) => {
			message.error(translations.errorGuardado);
			setLoading(false);
		});
	}

	const updateData = async() => {
		await physicalInvestorStatusAPI.updatePhysicalStatus(statusData).then((res: any) => {
			if(res.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
			}else{
				message.error(translations.errorGuardado);
			}
			setLoading(false);
		}
		).catch((err: any) => {
			message.error(translations.errorGuardado);
			setLoading(false);
		});
	}

	const saveData = async() => {
		setLoading(true);
		if (statusData?.id) {
			updateData();
		}
		else {
			postData();
		}
	}

	return {
		loading,
		statusData,
		visiblePoints,
		handleCheckedData,
		handleChangeData,
		saveData,
	};
};