import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';
import { TemplateDTO } from '../Models/Template';

export const TemplateAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getTemplateList = () => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Templates/GetAllTemplates`;
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        getTemplateList
    };
};
