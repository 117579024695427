import { Button, Col, Divider, Input, Popconfirm, Row } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { TaxHoldersAPI } from "../../../Api/TaxHoldersAPI";
import { useTaxHolders } from "../../../Hooks/useTaxHolders";
import { ITaxHolder } from "./ITaxHolder";
import { TaxHolder as TaxHolderModel } from "../../../Models/TaxHolders";
import "./TaxHolder.scss";
import FormLayout from "../../FormLayout/FormLayout";

const TaxHolder = ({
  baseUrl,
  translations,
  getAuthToken,
  unlockNextStep,
}: ITaxHolder) => {
  const taxHoldersAPI = TaxHoldersAPI(baseUrl, getAuthToken);

  const {
	loading,
	taxHolders,
	addRow,
	handleChangeData,
	saveData,
	deleteRow,
	errors
} = useTaxHolders({
    taxHoldersAPI,
    unlockNextStep,
    translations,
  });

  return (
    <FormLayout
      translations={translations}
      loading={loading}
      title={translations.tituloTitularFiscal}
	  subTitle={translations.encabezadoTitular}
      onClick={saveData}
      nextStep={unlockNextStep}
      enableNextStep={/* (taxHolders.length > 0 && taxHolders[0].id > 0) ? true : false */ true}
    >
      <Row className="tax-holder-sub-desription">
        <Col xs={24} md={24} lg={24} className="titles">
          <b>{translations.titularFiscalU}</b> {translations.titularFiscalD}
        </Col>
      </Row>
      <Row className="tax-holder-sub-desription">
        <Col xs={24} md={24} lg={24} className="titles">
          <b>{translations.entidadNoSujeta}</b> {translations.entidadNoSujetaD}
        </Col>
      </Row>
      <Button type="default" className="save-btn" onClick={addRow}>
        <PlusCircleOutlined />
        {translations.masTitular}
      </Button>
      {taxHolders.map((x: TaxHolderModel, i) => (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={12} className="titles">
            <label>
              {translations.nombrePropiedadFiscal}
            </label>
            <Input
              type="text"
              name="name"
			  status={errors[i].name ? "error" : ""}
              value={x.name}
              onChange={(e) => handleChangeData(e, i)}
            />
			<span className={`error ${errors[i].name ? "show" : null}`}>
				{translations.completeCampo}
			</span>
          </Col>
          <Col xs={24} md={12} lg={12} className="titles">
            <label >
              {translations.apellidoPropiedadFiscal}
            </label>
            <Input
              type="text"
              name="lastName"
			  status={errors[i].lastName ? "error" : ""}
              value={x.lastName}
              onChange={(e) => handleChangeData(e, i)}
            />
          <span className={`error ${errors[i].lastName ? "show" : null}`}>
            {translations.completeCampo}
          </span>
          </Col>
          <Col xs={24} md={12} lg={12} className="titles">
            <label>
              {translations.paisResidenciaFiscalTitular}
            </label>
            <Input
              type="text"
              name="taxResidency"
			  status={errors[i].taxResidency ? "error" : ""}
              value={x.taxResidency}
              onChange={(e) => handleChangeData(e, i)}
            />
            <span className={`error ${errors[i].taxResidency ? "show" : null}`}>
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={8} lg={8} className="titles">
            <label>
              {translations.porcentajepaticipacionTitular}
            </label>
            <Input
              type="text"
              name="participationPercentage"
			  status={errors[i].participationPercentage ? "error" : ""}
              value={x.participationPercentage}
              onChange={(e) => handleChangeData(e, i)}
            />
          <span className={`error ${errors[i].participationPercentage ? "show" : null}`}>
            {translations.completeCampo}
          </span>
          </Col>
		  <Col xs={24} md={4} lg={4} className="titles">
			<Popconfirm
				title={translations.eliminarTitularFiscal}
				okText={translations.si}
				cancelText={translations.no}
				onConfirm={() => deleteRow(i)}
			>
				<Button danger className="dlt-btn-benef">{translations.delete}</Button>
			</Popconfirm>
		</Col>
		<Divider />
        </Row>
      ))}
    </FormLayout>
  );
};

export default TaxHolder;
