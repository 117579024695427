import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

import { InvestorDTO } from '../Models/InvestorAPI';

export const InvestorAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getInvestors = (ContractId: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Investor`;
        return api.get(serviceUrl, { params: { ContractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const getCountries = () => {
        const serviceUrl = `${baseUrl}/masters-api/Country/GetCountries`;
        return api.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error: any) => error);
	};

	const postInvestors = (investors: InvestorDTO[]) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Investor`;
        return api.post(serviceUrl, investors)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
	}

	const updateInvestors = (investors: InvestorDTO[]) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Investor`;
        return api.put(serviceUrl, investors)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
	}

	const deleteInvestor = (id: number) => {
		const serviceUrl = `${baseUrl}/bookbuilding-api/Investor`;
		return api.delete(serviceUrl, { params: { id } })
			.then((response: AxiosResponse) => response.data)
			.catch((error: any) => error);
	}

	const updateShareType = (idContract: number, idShareType: number) => {
		const serviceUrl = `${baseUrl}/bookbuilding-api/Investor/UpdateShareType?IdContract=${idContract}&IdShareType=${idShareType}`;
        return api.put(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };


    return {
        getInvestors,
        getCountries,
		postInvestors,
		updateInvestors,
		deleteInvestor,
		updateShareType
    };
};
