import {
  ArrowRightOutlined,
  CaretRightFilled,
  LoadingOutlined,
  SaveOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin } from "antd";
import "./FormLayout.scss";

interface FormLayoutProps {
  translations: any;
  loading: boolean;
  children: any;
  subTitle?: any;
  title: string;
  onClick?: () => void;
  disabledSaveButton?: boolean;
  nextStep?: () => void;
  enableNextStep?: boolean;
  confirm?: any
}

const FormLayout = (props: FormLayoutProps) => {
  const {
    translations,
    loading,
    children,
    subTitle,
    title,
    onClick,
    disabledSaveButton,
    nextStep,
	enableNextStep,
	confirm
  } = props;

  const confirmModal = () => {
  Modal.confirm({
    title: translations.confirm,
    icon: <ExclamationCircleOutlined />,
    content: confirm,
    okText: translations.si,
    cancelText: translations.no,
	onOk: () => onClick && onClick()
  });
};

  return (
    <div className="data-wrapper">
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined style={{ fontSize: 34, color: "black" }} spin />
        }
      >
        <Row justify="space-between">
          <h2>
            <CaretRightFilled className="caret-green" />
            {title}
          </h2>
          <Button type="text" className="arrow-btn" onClick={nextStep} disabled={!enableNextStep}>
            <ArrowRightOutlined />
          </Button>
        </Row>
        {subTitle && <p>{subTitle}</p>}
        <Row gutter={[0, 16]} className="data-form-wrapper">
          {children}
        </Row>
        {onClick && (
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                loading={loading}
                onClick={() => confirm ? confirmModal() : onClick()}
                disabled={disabledSaveButton}
                className="saveButton"
              >
                <SaveOutlined />
                {translations.botonGuardar}
              </Button>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default FormLayout;
