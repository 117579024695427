import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Spin,
  Select,
  Radio,
  DatePicker,
  Tooltip,
  Checkbox,
  Divider,
  Switch,
} from "antd";
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { IRepresentativesForm } from "./IRepresentativesForm";

import "./RepresentativesForm.scss";
import { useRepresentativesForm } from "../../../Hooks/useRepresentativesForm";
import { InvestorAPI } from "../../../Api/InvestorAPI";
import { ContractAPI } from "../../../Api/ContractAPI";
import FormLayout from "../../FormLayout/FormLayout";
import { RepresentativesAPI } from "../../../Api/RepresentativesAPI";
import { Representative } from "../../../Models/Representative";

const { Option } = Select;

const RepresentativesForm = ({
  baseUrl,
  translations,
  unlockNextStep,
  getAuthToken,
}: IRepresentativesForm) => {
  const contractAPI = ContractAPI(baseUrl, getAuthToken);
  const representativesAPI = RepresentativesAPI(baseUrl, getAuthToken);
  const investorAPI = InvestorAPI(baseUrl, getAuthToken);
  const {
    loading,
    noRepresentativesError,
    errors,
    nationalitiesList,
    representatives,
    addRow,
    handleChangeData,
    deleteRow,
    handleSelectData,
    handleChangeDate,
    handleChangePRPData,
    saveData
  } = useRepresentativesForm({
    translations,
    contractAPI,
    representativesAPI,
    unlockNextStep,
	investorAPI,
  });

  return (
    <FormLayout
      translations={translations}
      loading={loading}
      title={translations.representativeAttorneyData}
      onClick={saveData}
	  nextStep={unlockNextStep}
	  enableNextStep={(representatives.length > 0 && representatives[0].id > 0) ? true : false}
    >
      <Row justify="space-between">
        <Col>
          <Button
            type="default"
            className="save-btn"
            /* disabled={maxNumSignatories} */
            onClick={addRow}
          >
            <PlusCircleOutlined />
            {translations.representativeAttorney}
          </Button>
        </Col>
      </Row>
      {noRepresentativesError ? (
        <span style={{ color: "red" }}>
          Debe agregar al menos un representante
        </span>
      ) : null}
      {representatives.map((representative: Representative, i: number) => {
        return (
          <Col xs={24} md={24} lg={24} className="signatorieRow">
            <Row gutter={[16, 16]} align="top">
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">
                  {translations.nombreFirmante}
                </label>
                <Input
                  type="text"
                  value={representative.name}
                  onChange={(e) => handleChangeData(e.target.value, i, "name")}
                  status={errors[i].name ? "error" : ""}
                  placeholder={translations.nombreFirmante}
                />
                <span className={`error ${errors[i].name ? "show" : null}`}>
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">
                  {translations.apellidosFirmante}
                </label>
                <Input
                  type="text"
                  value={representative.lastName}
				  className="input-field"
                  onChange={(e) => handleChangeData(e.target.value, i, "lastName")}
                  status={errors[i].lastName ? "error" : ""}
                  placeholder={translations.apellidosFirmante}
                />
                <span className={`error ${errors[i].lastName ? "show" : null}`}>
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">
                  {translations.representativeAttorney}
                </label>
                <Select
                  value={representative.administrator}
                  placeholder={"Representante / Apoderado"}
				  className="input-field"
                  onChange={handleSelectData("administrator", i)}
                  status={errors[i].administrator ? "error" : ""}
                >
                  <Option value={"Representante"}>
                    {translations.representative}
                  </Option>
                  <Option value={"Apoderado"}>{translations.attorney}</Option>
                </Select>
                <span
                  className={`error ${errors[i].administrator ? "show" : null}`}
                >
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">
                  {translations.representationType}
                </label>
                <Select
                  value={representative.administratorType}
				  className="input-field"
                  placeholder={"Tipo de Representación"}
                  onChange={handleSelectData("administratorType", i)}
                  status={errors[i].administratorType ? "error" : ""}
                >
                  <Option value={"Único"}>{translations.sole}</Option>
                  <Option value={"Solidario"}>{translations.solidary}</Option>
                  <Option value={"Mancomunado"}>{translations.joint}</Option>
                </Select>
                <span className={`error ${ errors[i].administratorType ? "show" : null}`}>
                  {translations.completeCampo}
                </span>
              </Col>
            </Row>
            <Row gutter={[16, 16]} align="top">
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">
                  {translations.fechaNacimiento}
                </label>
                <DatePicker
				  allowClear={false}
                  className="input-field"
                  name="birthDate"
                  value={representative.birthDate ? moment(representative.birthDate) : undefined}
                  format="DD/MM/YYYY"
                  onChange={(e) => handleChangeDate(e, "birthDate", i)}
                  status={errors[i].birthDate ? "error" : ""}
                />
                <span
                  className={`error ${errors[i].birthDate ? "show" : null}`}
                >
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">{translations.documentType}</label>
                <Select
                  value={representative.documentType}
                  placeholder={translations.documentType}
				  className="input-field"
                  status={errors[i].documentType ? "error" : ""}
                  onChange={handleSelectData("documentType", i)}
                >
                  <Option value={"DNI"}>DNI-NIF</Option>
                  <Option value={"Pasaporte nacional"}>
                    {translations.passport}
                  </Option>
                  <Option value={"Tarjeta de residencia"}>
                    {translations.residentCard}
                  </Option>
                  <Option value={"CIF"}>CIF</Option>
                </Select>
                <span className={`error ${errors[i].documentType ? "show" : null}`}>
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">{translations.docNumber}</label>
                <Input
                  type="text"
                  value={representative.documentNumber}
                  onChange={(e) => handleChangeData(e.target.value, i, "documentNumber")}
                  status={errors[i].documentNumber ? "error" : ""}
                  placeholder={translations.documentNumber}
                />
                <span
                  className={`error ${errors[i].documentNumber ? "show" : null}`}
                >
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">
                  {translations.expirationDate}
                </label>
                <DatePicker
				  allowClear={false}
                  className="input-field"
                  value={
                    representative.documentExpiration ? moment(representative.documentExpiration): undefined
                  }
                  format="DD/MM/YYYY"
                  onChange={(e) => handleChangeDate(e, "documentExpiration", i)}
                  status={errors[i].documentExpiration ? "error" : ""}
                />
                <span className={`error ${errors[i].documentExpiration ? "show" : null}`}>
                  {translations.completeCampo}
                </span>
              </Col>
			  <Col xs={24} md={6} lg={6} className="titles">
                <label className="required">{translations.email}</label>
                <Input
                  type="text"
                  value={representative.email}
                  onChange={(e) => handleChangeData(e.target.value, i, "email")}
                  status={errors[i].email ? "error" : ""}
                  placeholder={translations.email}
                />
                <span
                  className={`error ${errors[i].email ? "show" : null}`}
                >
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={6} lg={6} className="titles">
                <label className="required"> {translations.nacionalidad}</label>
                <Select
                  className="input-field"
                  value={representative.nationality}
                  onChange={handleSelectData("nationality", i)}
                  status={errors[i].nationality ? "error" : ""}
                  placeholder="Seleccione una opción"
                  showSearch
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {nationalitiesList.map((nationality: any) => {
                    return (
                      <Option value={nationality.name_lang2}>
                        {nationality.name_lang2}
                      </Option>
                    );
                  })}
                </Select>
                <span
                  className={`error ${errors[i].nationality ? "show" : null}`}
                >
                  {translations.completeCampo}
                </span>
              </Col>
              <Col xs={24} md={4} lg={4} className="titles">
				<div className="isSignatory">
					<label> {translations.isSignatory}</label>
					<Switch
						disabled
						checked={representative.isSignatory}
						onChange={(e) => handleChangeData(e, i, "isSignatory")}
						/>
				</div>
			  </Col>
              {!representative.isSignatory && 
			  <Col xs={24} md={2} lg={2} className="titles" >
                <Popconfirm
                  title={translations.eliminarFirmante}
                  okText={translations.si}
                  cancelText={translations.no}
                  onConfirm={() => deleteRow(i)}
                >
                  <Button danger className="dlt-btn">{translations.delete}</Button>
                </Popconfirm>
              </Col>}
            </Row>
			<Divider />
		  </Col>
		  
        );
      })}
      <Row gutter={[16, 16]} align="middle" className="prp5Q">
        <Col xs={24} md={24} lg={24}>
		<Checkbox
			name="prP5"
			checked={representatives[0].prP5}
			onChange={(e) => handleChangeData(e.target.checked, 0, "prP5")}
			>
			{translations.isRepresentativePRP5} 
			<Tooltip placement="topLeft" title={translations.prpTooltip}>
              <InfoCircleOutlined className="tooltip" />
            </Tooltip>
		</Checkbox>
        </Col>
      </Row>
      {representatives[0].prP5 && (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={12} className="titles">
            <label className="required">{translations.completeNamePRP51}</label>
            <Input
              type="text"
              name="namePRP51"
              value={representatives[0].namePRP51}
              onChange={handleChangePRPData}
              status={errors[0].namePRP51 ? "error" : ""}
            />
            <span className={`error ${errors[0].namePRP51 ? "show" : null}`}>
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={12} lg={12} className="titles">
            <label className="required">{translations.positionPRP51}</label>
            <Input
              type="text"
              name="positionPRP51"
              value={representatives[0].positionPRP51}
              onChange={handleChangePRPData}
              status={errors[0].positionPRP51 ? "error" : ""}
            />
            <span
              className={`error ${errors[0].positionPRP51 ? "show" : null}`}
            >
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={12} lg={12} className="titles">
            <label> {translations.completeNamePRP52}</label>
            <Input
              type="text"
              name="namePRP52"
              value={representatives[0].namePRP52}
              onChange={handleChangePRPData}
              status={errors[0].namePRP52 ? "error" : ""}
            />
            <span className={`error ${errors[0].namePRP52 ? "show" : null}`}>
              {translations.completeCampo}
            </span>
          </Col>
          <Col xs={24} md={12} lg={12} className="titles">
            <label> {translations.positionPRP52}</label>
            <Input
              type="text"
              name="positionPRP52"
              value={representatives[0].positionPRP52}
              onChange={handleChangePRPData}
              status={errors[0].positionPRP52 ? "error" : ""}
            />
            <span
              className={`error ${errors[0].positionPRP52 ? "show" : null}`}
            >
              {translations.completeCampo}
            </span>
          </Col>
        </Row>
      )}
    </FormLayout>
  );
};

export default RepresentativesForm;
