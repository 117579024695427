import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const SignaturitAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const createSignature = (idContract: number, providerName: string) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Signature/CreateSignature?IdContract=${idContract}&providerName=${providerName}`;
        return api.post(serviceUrl)
    };

    const sendContract = (idContract: number, providerName: string) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Signature/SendContract?IdContract=${idContract}&providerName=${providerName}`;
        return api.post(serviceUrl)
    };

    const cancelContract = (idContract: string, providerName: string) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Signature/CancelContract?ContractId=${idContract}&providerName=${providerName}`;
        return api.put(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
    
    return {
        createSignature,
        cancelContract,
		sendContract
    };
};
