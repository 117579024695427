import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message, notification } from "antd";

import { IUseCreateSignature } from "./IUseCreateSignature";

export interface signaturitParams {
  id: number;
  paramName: string;
  paramValue: string;
}

export const useCreateSignature = ({
  translations,
  contractAPI,
  navigate,
}: IUseCreateSignature) => {
  const [searchParams, _] = useSearchParams();

  const [contractData, setContractData]: any = useState();
  const [sendedContract, setSendedContract] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.has("ContractId")) {
      getContractData(Number(searchParams.get("ContractId")));
    }
  }, [searchParams.get("ContractId")]);

  const getContractData = (contractId: number) =>
    contractAPI.getContract(contractId).then((res: any) => {
      if (res.success) {
		setContractData(res.data);
        if (res.data.idContractState !== 1) {
          setSendedContract(true);
        }
      }
      return res;
    });

  const sendContractToValidate = () => {
    setLoading(true);
    let contractWithNewState = { ...contractData };
    contractWithNewState.idContractState = 2;
    contractAPI.updateContract(contractWithNewState).then((res) => {
        if (res.success) {
			message.success(translations.contratoEnviado);
			irAlInicio();
        }
      })
      .catch((err) =>
        notification.error({ message: "Contract couldn't be sent" })
      );
    setLoading(false);
  };

  const irAlInicio = () => navigate("/");

  return {
    loading,
    sendedContract,
    sendContractToValidate,
    irAlInicio,
  };
};
