import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const InstitutionsAPI = (baseUrl: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseUrl,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getSuitabilityTestEnabled = (idInstitution: number) => {
        const serviceUrl = `${baseUrl}/bookbuilding-api/Institution/GetSuitabilityTestEnabled`;
        return api.get(serviceUrl, { params: { idInstitution } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
		getSuitabilityTestEnabled    
	};
};